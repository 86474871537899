export default {
  apiKey: "AIzaSyCMceIUssn9hORdTQR-_AHdwwZMMdZm4ws",
  authDomain: "gestcamp-vm-288914.firebaseapp.com",
  databaseURL: "https://innes.firebaseio.com",
  projectId: "gestcamp-vm-288914",
  storageBucket: "gestcamp-vm-288914.appspot.com",
  messagingSenderId: "574236475610",
  appId: "1:574236475610:web:42486046130e77e0a365e3",
  measurementId: "G-Y85PZT270F",
};

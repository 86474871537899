// **  Initial State
const initialState = {
  userData: {},
  dataScanQR: null,
  logged: false,
  errorLogin: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data,
        logged: true,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName],
      };
    case "ERROR_LOGIN":
      return {
        ...state,
        errorLogin: action.data,
      };
    case "DATA_SCAN_QR":
      return {
        ...state,
        dataScanQR: action.data,
      };
    case "CURRENT_USER":
      return {
        ...state,
        userData: action.data,
      };
    case "LOGOUT":
      const obj = { ...action };
      delete obj.type;
      return { ...state, logged: false, userData: {}, ...obj };
    default:
      return state;
  }
};

export default authReducer;

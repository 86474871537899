import { detectar } from "../../../utility/Utils";

// **  Initial State
const initialState = {
  dispositivo: {
    esMovil: false,
    esTablet: false,
    esAndroid: false,
    esiPhone: false,
    esiPad: false,
    esOrdenador: false,
    esWindows: false,
    esLinux: false,
    esMac: false,
  },
};

const dispositivoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLE_DISPOSITIVO":
      return {
        ...state,
        dispositivo: detectar(),
      };

    default:
      return state;
  }
};

export default dispositivoReducer;

// ** Auth Endpoints

import { API_OAUTH_URL } from "../../constants";

export default {
  loginEndpoint: `${API_OAUTH_URL}/auth/login`,
  registerEndpoint: "/jwt/register",
  refreshEndpoint: `${API_OAUTH_URL}/auth/refreshtoken`,
  logoutEndpoint: `${API_OAUTH_URL}/auth/logout`,
  googleAuthUrl: `${API_OAUTH_URL}/auth/login/api`,

  // ** This will be prefixed in authorization header with token
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "token",
  storageRefreshTokenKeyName: "refreshToken",
  storageCrypto: "versionK",
};

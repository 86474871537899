const initialState = {
    itemTabSelect: null,
  };
  
  const ItemTabState = (state = initialState, action) => {
    switch (action.type) {
      case "SELECT_ITEM_TAB":
        return { ...state, itemTabSelect: action.itemTab };
  
      case "CLEAR_ITEM_TAB":
        return { ...state, itemTabSelect: null };
  
      default:
        return { ...state };
    }
  };
  
  export default ItemTabState;
  
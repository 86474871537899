const initialState = {
    roleSelect: null,
  };
  
  const RoleState = (state = initialState, action) => {
    switch (action.type) {
      case "SELECT_ROLE":
        return { ...state, roleSelect: action.role };
  
      case "CLEAR_ROLE":
        return { ...state, roleSelect: null };
  
      default:
        return { ...state };
    }
  };
  
  export default RoleState;
import { types } from "../action";

// ** Initial State
const initialState = {
  loading: true,
  loadingFilterCampania: true,
  dataFilterProp: {},
  regiones: [],
  agencias: [],
  campanias: [],
  usuarios: [],
  filterCampania: [],
};

const FilterGeneralState = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOADING:
      return { ...state, loading: action.loading };
    case types.SET_LOADING_FILTER_CAMPANIA:
      return { ...state, loadingFilterCampania: action.loadingFilterCampania };  
    case types.SET_DATA_FILTER_PROP:
      return { ...state, dataFilterProp: action.dataFilterProp };
    case types.SET_REGIONES:
      return { ...state, regiones: action.regiones };
    case types.SET_AGENCIAS:
      return { ...state, agencias: action.agencias };
    case types.SET_CAMPANIAS:
      return { ...state, campanias: action.campanias };
    case types.SET_USUARIOS:
      return { ...state, usuarios: action.usuarios };
    case types.SET_FILTER_CAMPANIA:
      return { ...state, filterCampania: action.filterCampania };
    default:
      return { ...state };
  }
};
export default FilterGeneralState;

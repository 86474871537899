const initialState = {
    selectCampania: null,
    loading: false,
  };
  
  const CampaniaState = (state = initialState, action) => {
    switch (action.type) {
      case "SET_LOADING":
        return { ...state, loading: action.loading };
      case "SELECT_CAMPANIA":
        return { ...state, selectCampania: action.select,};
  
      case "CLEAR_CAMPANIA":
        return { ...state, selectCampania: null,};
  
      default:
        return { ...state };
    }
  };
  
  export default CampaniaState;
  
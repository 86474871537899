import { isNumeric } from "../../../../../utility/Utils";

// ** Initial State
const initialState = {
  loadingData: false,
  totalCount: 0,
  pageSize: 10,
  currentPage: 1,
  stringOrder: "",
  lastQuery: "",
  sorting: "",
  data: [],

  selectedProfileCliente: null,
  documento: null,
  loading: false,
  reloadProfileCliente: false,

  loadingFilters: false,
  allCampanias: [],
  allAgencias: [],
  allAgenciasBK: [],
  allRegiones: [],
  allUsuarios: [],
  campaniaSelected: null,
  agenciaSelected: null,
  regionSelected: null,
  usuarioSelected: null,

  objFilter: {},
  searchTable: null,

  loadingForm: false,
  formCampania: [],
  objRegistro: null,

  loadingTimeLineCliente: false,

  dataTimeLineClientes: [],
  filterCampania: [],
  arrFilterSelected: [],
  filterSelected: null,
};

const clientesAsignadosCampanias = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: action.loading };
    case "LOADING_DATA":
      return { ...state, loadingData: action.loading };
    case "LOADING_FORM":
      return { ...state, loadingForm: action.loading };
    case "LOADING_TIMELINE_CLIENTE":
      return { ...state, loadingTimeLineCliente: action.loading };
    case "LOADING_FILTROS":
      return { ...state, loadingFilters: action.loading };

    case "REGRESAR":
      return { ...state, selectedProfileCliente: null };

    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        totalCount: action.totalCount,
        loadingData: action.loading,

        //params: action.params,
      };
    case "SET_DOCUMENTO_PROFILE":
      return {
        ...state,
        documento: action.documento,
      };

    case "CLEAR_PROFILE_CLIENTE":
      return {
        ...state,
        selectedProfileCliente: null,
      };
    case "GET_PROFILE_CLIENTE":
      return {
        ...state,
        selectedProfileCliente: action.selectedProfileCliente,
        loading: action.loading,
      };
    case "RELOAD_PROFILE_CLIENTE":
      return {
        ...state,
        reloadProfileCliente: !state.reloadProfileCliente,
      };

    case "CLEAR_FILTER":
      return {
        ...state,
        campaniaSelected: null,
        agenciaSelected: null,
        regionSelected: null,
        usuarioSelected: null,
        objFilter: {},
      };
    case "GET_REGIONES":
      return { ...state, allRegiones: action.regiones };
    case "GET_AGENCIAS":
      return {
        ...state,
        allAgencias: action.agencias,
        allAgenciasBK: action.agencias,
      };
    case "GET_USUARIOS":
      return { ...state, allUsuarios: action.usuarios };

    case "GET_CAMPANIAS":
      return { ...state, allCampanias: action.campanias };
    case "REGION_SELECTED":
      let agencias = state.allAgenciasBK;

      if (action.region) {
        agencias = agencias.filter((b) => {
          return b.codreg === action.region.codreg;
        });
        return {
          ...state,
          regionSelected: action.region,
          agenciaSelected: null,
          allAgencias: agencias,
        };
      } else {
        return {
          ...state,
          regionSelected: null,
          agenciaSelected: null,
          allAgencias: agencias,
        };
      }

    case "AGENCIA_SELECTED":
      if (action.agencia) {
        return {
          ...state,
          agenciaSelected: action.agencia,
        };
      } else {
        return {
          ...state,
          agenciaSelected: null,
        };
      }

    case "CAMPANIA_SELECTED":
      if (action.campania) {
        return {
          ...state,
          campaniaSelected: action.campania,
        };
      } else {
        return {
          ...state,
          campaniaSelected: null,
        };
      }
    case "USUARIO_SELECTED":
      //console.log("action.usuario", action.usuario);
      if (action.usuario) {
        return {
          ...state,
          usuarioSelected: action.usuario,
        };
      } else {
        return {
          ...state,
          usuarioSelected: null,
        };
      }
    case "GET_OBJ_FILTER":
      //console.log("GET_OBJ_FILTER");
      var data = {};
      if (state.agenciaSelected) data.codage_in = state.agenciaSelected.codage;
      if (state.regionSelected) data.codreg_in = state.regionSelected.codreg;
      if (state.campaniaSelected) data.campania_in = state.campaniaSelected.id;
      if (state.usuarioSelected)
        data.username_in = state.usuarioSelected.usuario;

      if (state.arrFilterSelected.length > 0) {
        //concaterar objeto state.arrFilterSelected
        let arrStrFilter = [];
        state.arrFilterSelected.forEach((item) => {
          arrStrFilter.push(item.filter + "-" + item.value);
        });

        data.filterSelected = arrStrFilter.join(",");
      }

      /*   if (state.filterSelected) {
        data.filterSelected =
          state.filterSelected.filter + "-" + state.filterSelected.value;
      } */
      //console.log("objFilter", data);

      return {
        ...state,
        objFilter: data,
      };

    case "SEARCH_TABLE":
      let search = null;
      if (action.value !== null && action.value !== "") {
        if (isNumeric(action.value)) {
          search = [
            { key: "documento", operator: "contains", value: action.value },
          ];
        } else {
          search = [
            {
              key: "nombrecompleto",
              operator: "contains",
              value: action.value,
            },
            //{ key: "apellidos", operator: "contains", value: action.value },
          ];
        }
      }
      return {
        ...state,
        searchTable: search,
      };

    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.page,
      };
    case "GET_FORM_CAMPANIA":
      return {
        ...state,
        formCampania: action.formCampania,
      };

    case "SET_OBJ_REGISTRO":
      return {
        ...state,
        objRegistro: action.obj,
      };

    case "SET_TIMELINE_CLIENTE":
      return {
        ...state,
        dataTimeLineClientes: action.data,
        loadingTimeLineCliente: false,
      };
    case "CLEAR_FILTER_CAMPANIA":
      return {
        ...state,
        filterCampania: [],
        arrFilterSelected: [],
      };
    case "SET_FILTER_CAMPANIA":
      return {
        ...state,
        filterCampania: action.data,
      };

    case "SET_FILTER_SELECTED":
      let filter = action.filter;

      //console.log("filterxxx", filter);

      let arrFilter = state.arrFilterSelected;

      if (filter === null) {
        let rs2 = arrFilter.filter((item) => item.filter !== action.idFilter);
        arrFilter = rs2;
      } else {
        let rs = arrFilter.filter((item) => item.filter === filter.filter);
        if (rs.length > 0) {
          let rs2 = arrFilter.filter((item) => item.filter !== filter.filter);
          arrFilter = rs2;
          arrFilter.push(filter);
        } else {
          arrFilter.push(filter);
        }
      }

      return {
        ...state,
        arrFilterSelected: arrFilter,
      };

    default:
      return { ...state };
  }
};
export default clientesAsignadosCampanias;

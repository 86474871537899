// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import opcMenu from "./menu";
import navbar from "./navbar";
import layout from "./layout";
import FirebaseDB from "./firebase"
import dispositivo from "./dispositivo";
import clientesAsignadosCampanias from "@src/views/user/clientes-asignados/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ReasignarClientesState from "@src/views/user/reasingar-clientes/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import InfoCreditoVacacionState from "@src/views/user/vacaciones-asesor/store/reducer";
import usersActivity from "@src/views/user/activity/store/reducer";
import PricingState from "@src/views/apps/pricing/store/reducer";
import ResetUserQr from "@src/views/user/reset-user/store/reducer";
import TableFilter from "@src/views/user/estadisticas/Graficos/table-filter/store/reducer";
import AdminOpcMenuState from "@src/views/user/estadisticas/Graficos/admin/monitor/store/reducer";
import TabState from "@src/views/user/estadisticas/Graficos/admin/tab-monitor/store/reducer";
import ItemTabState from "@src/views/user/estadisticas/Graficos/admin/item-tab-monitor/store/reducer";
import GraficoState from "@src/views/user/estadisticas/Graficos/admin/grafico-monitor/store/reducer";
import RoleState from "@src/views/user/estadisticas/Graficos/admin/rol/store/reducer";
import CampaniaState from "@src/views/user/estadisticas/Graficos/campanias/store/reducer";
import TrackerState from "../../views/user/estadisticas/tracker/store/reducer";
import SessionTimerState from "../../views/user/session-timer/store/reducer";
import FilterGeneralState from "../../views/user/estadisticas/Graficos/filtros/store/reducer";

const rootReducer = combineReducers({
  auth,
  todo,
  opcMenu,
  navbar,
  layout,
  clientesAsignadosCampanias,
  calendar,
  ReasignarClientesState,
  InfoCreditoVacacionState,
  usersActivity,
  PricingState,
  ResetUserQr,
  dispositivo,
  TableFilter,
  AdminOpcMenuState,
  TabState,
  ItemTabState,
  GraficoState,
  RoleState,
  FirebaseDB,
  CampaniaState,
  TrackerState,
  SessionTimerState,
  FilterGeneralState,
});

export default rootReducer;

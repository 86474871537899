export const VERSION_K = "***#Equ1P0b1b4#***";

export const  DATABASE_FIREBASE = process.env.REACT_APP_DATABASE_FIREBASE;
export const  DATABASE_AD_FIREBASE = process.env.REACT_APP_DATABASE_AD_FIREBASE;

export const API_LB = process.env.REACT_APP_API_LB;
export const API_OAUTH_URL = process.env.REACT_APP_API_OAUTH_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;
export const API_BASE_URL_MONITOR = process.env.REACT_APP_API_BASE_URL_MONITOR;
export const API_UPLOAD_FILE = process.env.REACT_APP_API_UPLOAD_FILE;
export const API_TRACKIND = process.env.REACT_APP_API_TRACKIND;

export const API_NOTIFICACION = process.env.REACT_APP_API_NOTIFICACION;

export const API_FORM = process.env.REACT_APP_API_FORM;

export const API_CAMPANIAS = process.env.REACT_APP_API_CAMPANIAS;
export const API_CALENDAR = process.env.REACT_APP_API_CALENDAR;
export const API_TODO = process.env.REACT_APP_API_TODO;

export const API_WHATSAPP = process.env.REACT_APP_API_WHATSAPP;
export const API_WHATSAPP_QR = process.env.REACT_APP_API_WHATSAPP_QR;

export const API_SEND_PUB = process.env.REACT_APP_API_SEND_PUB;

export const API_CONSULTA_RUC = "https://ruc.com.pe/api/v1/consultas";
export const TOKEN_CONSULTA_RUC =
  "360e54f4-ea06-40fa-ae90-8e0090fca6bf-0a35f845-1892-4316-aca9-674caba81030";

export const API_PRICING = process.env.REACT_APP_API_PRICING;

export const ACCESS_TOKEN = "token";

export const OAUTH2_REDIRECT_URI = process.env.REACT_APP_OAUTH2_REDIRECT_URI;

export const GOOGLE_AUTH_URL =
  API_OAUTH_URL +
  "/oauth2/authorize/google?redirect_uri=" +
  process.env.REACT_APP_OAUTH2_REDIRECT_URI;

export const MAPS_API_KEY = "AIzaSyDHRDThgzDj-ddYXl92kImms_xnnNYSZP4";

export const defaulOpcionCalendar = [
  { value: "General", label: "General", color: "primary" },
];

export const optionsCalendar = [
  { value: "Contactar", label: "Contactar", color: "success" },
  { value: "Promoción", label: "Promoción", color: "dark" },
  { value: "Visita", label: "Visita", color: "info" },
  { value: "Llamada", label: "Llamada", color: "warning" },
  { value: "Cobranza", label: "Cobranza", color: "danger" },
  { value: "General", label: "General", color: "primary" },
];

export const calendarsColor = {
  Contactar: "success",
  Promoción: "dark",
  Visita: "info",
  Llamada: "warning",
  Cobranza: "danger",
  General: "primary",
};

export const alertExpireSession =false;
//export const PWI_USR = "pbiembed@inteligencianegocios2.onmicrosoft.com";
//export const PWI_PWD = "C4j4p1ur412345";
//export const PWI_CLIEND_ID = "5ff39f56-3e0e-49d1-a0f6-6107d97ad0f3";

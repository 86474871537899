export const START_SESSION = "START_SESSION";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const VIEW_ALERT = "VIEW_ALERT";
export const SET_DATE = "SET_DATE";

export const setDate = (date) => ({
  type: SET_DATE,
  payload: date,
});

export const resetTimerSession = (timeLeft) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_RESET_TIMER_SESSION",
      timeLeft: timeLeft,
    });
  };
};

export const decrement = () => {
  return async (dispatch, getState) => {
    //const { session } = ;
    dispatch({
      type: "SET_TIME_LEFT",
      timeLeft: getState().SessionTimerState.timeLeft - 1,
    });
  };
};

export const startSession = (duration) => ({
  type: START_SESSION,
  payload: duration,
});

export const updateSession = (remainingTime) => ({
  type: UPDATE_SESSION,
  payload: remainingTime,
});

export const viewAlert = (viewAlert) => ({
  type: VIEW_ALERT,
  payload: viewAlert,
});

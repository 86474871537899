const initialState = {
  loadingData: false,
  totalCount: 0,
  pageSize: 10,
  currentPage: 1,
  stringOrder: "",
  lastQuery: "",
  sorting: "",
  searchTerm: "",
  data: [],

  loadingFilters: false,
  allCampanias: [],
  allAgencias: [],
  allAgenciasBK: [],
  allRegiones: [],
  allUsuarios: [],
  campaniaSelected: null,
  agenciaSelected: null,
  regionSelected: null,
  usuarioSelected: null,

  modalOpen: false,
  selectedReasignar: null,
  loadingDataReasignarAsesor: false,
  dataReasignarAsesorCampania: [],
  dataSelectedReasignar: [],

  loadingProcesarReasignar: false,

  paramsReasignarData: null,
};

const ReasignarClientesState = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_FILTROS":
      return { ...state, loadingFilters: action.loading };

    case "LOADING_DATA_REASIGNAR":
      return { ...state, loadingData: action.loading };

    case "LOADING_DATA_REASIGNAR_ASESOR":
      return { ...state, loadingDataReasignarAsesor: action.loading };
    case "LOADING_PROCESAR_REASIGNAR":
      return { ...state, loadingProcesarReasignar: action.loading };

    case "CLEAR_FILTER":
      return {
        ...state,
        campaniaSelected: null,
        agenciaSelected: null,
        regionSelected: null,
        usuarioSelected: null,
        objFilter: {},
      };

    case "SET_PARAMS_DATA_REASIGNAR":
      return { ...state, paramsReasignarData: action.params };
    case "GET_REGIONES":
      return { ...state, allRegiones: action.regiones };

    case "GET_AGENCIAS":
      return {
        ...state,
        allAgencias: action.agencias,
        allAgenciasBK: action.agencias,
      };

    case "GET_USUARIOS":
      return { ...state, allUsuarios: action.usuarios };

    case "GET_CAMPANIAS":
      return { ...state, allCampanias: action.campanias };

    case "REGION_SELECTED":
      let agencias = state.allAgenciasBK;

      if (action.region) {
        agencias = agencias.filter((b) => {
          return b.codreg === action.region.codreg;
        });
        return {
          ...state,
          regionSelected: action.region,
          agenciaSelected: null,
          allAgencias: agencias,
        };
      } else {
        return {
          ...state,
          regionSelected: null,
          agenciaSelected: null,
          allAgencias: agencias,
        };
      }

    case "AGENCIA_SELECTED":
      if (action.agencia) {
        return {
          ...state,
          agenciaSelected: action.agencia,
        };
      } else {
        return {
          ...state,
          agenciaSelected: null,
        };
      }

    case "CAMPANIA_SELECTED":
      if (action.campania) {
        return {
          ...state,
          campaniaSelected: action.campania,
        };
      } else {
        return {
          ...state,
          campaniaSelected: null,
        };
      }
    case "USUARIO_SELECTED":
      if (action.usuario) {
        return {
          ...state,
          usuarioSelected: action.usuario,
        };
      } else {
        return {
          ...state,
          usuarioSelected: null,
        };
      }
    case "GET_OBJ_FILTER":
      var data = {};
      if (state.agenciaSelected) data.codage_in = state.agenciaSelected.codage;
      if (state.regionSelected) data.codreg_in = state.regionSelected.codreg;
      if (state.campaniaSelected) data.campania_in = state.campaniaSelected.id;
      if (state.usuarioSelected)
        data.username_in = state.usuarioSelected.usuario;

      return {
        ...state,
        objFilter: data,
      };

    case "GET_DATA_REASIGNAR":
      return {
        ...state,
        data: action.data,
        totalCount: action.totalCount,
        loadingData: action.loading,

        //params: action.params,
      };
    case "GET_DATA_REASIGNAR_ASESOR":
      return {
        ...state,
        dataReasignarAsesorCampania: action.data,
        //totalCount: action.totalCount,
        //params: action.params,
      };

    case "SEARCH_TABLE_REASIGNAR":
      let search = null;
      if (action.value !== null && action.value !== "") {
        search = [
          { key: "documento", operator: "contains", value: action.value },
          { key: "nombres", operator: "contains", value: action.value },
          { key: "apellidos", operator: "contains", value: action.value },
        ];
      }
      return {
        ...state,
        searchTable: search,
      };

    case "OPEN_MODAL_REASIGNAR":
      return {
        ...state,
        modalOpen: true,
        //params: action.params,
      };

    case "CLOSE_MODAL_REASIGNAR":
      return {
        ...state,
        modalOpen: false,
        selectedReasignar: null,
        dataSelectedReasignar: [],
        //params: action.params,
      };

    case "SELECTED_REASIGNAR":
      return {
        ...state,
        selectedReasignar: action.row,
        //params: action.params,
      };
    //
    case "DATA_SELECTED_REASIGNAR":
      return {
        ...state,
        dataSelectedReasignar: action.rows,
        //params: action.params,
      };
    default:
      return { ...state };
  }
};

export default ReasignarClientesState;

//** Initial State
const initialState = {
  objFilter: {},
};

const TableFilter = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        objFilter: action.objFilter,
      };   
    default:
        return { ...state };
  }
};
export default TableFilter;
const initialState = {
  loading: false,
  filterUsersReset: [],
  usersResetAll: [],
  selected: null,
  optMotivoReset: [],
};

const ResetUserQr = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_USER_RESET":
      return { ...state, loading: action.loading };
    case "GET_USUARIOS_RESET":
      return { ...state, usersResetAll: action.data };
    case "SET_USUARIOS_RESET_FILTER":
      return { ...state, filterUsersReset: action.data };
    case "SELECT_USER_RESET":
      return { ...state, selected: action.select };
    case "OPT_MOTIVOS_RESET":
      return { ...state, optMotivoReset: action.data };
    default:
      return { ...state };
  }
};

export default ResetUserQr;

const initialState = {
  tabSelect: null,
};

const TabState = (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_TAB":
      return { ...state, tabSelect: action.tab };

    case "CLEAR_TAB":
      return { ...state, tabSelect: null };

    default:
      return { ...state };
  }
};

export default TabState;

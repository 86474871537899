// ** Initial State
const initialState = {
  loading: false,
  tasks: [],
  tasksS: [],
  selectedTask: {},
  userSearch: null,
  userSearchObj: null,
  params: {
    filter: "",
    q: "",
    sort: "",
    tag: "",
    obj: null,
  },
};

const TodoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_TODO":
      return {
        ...state,
        loading: action.loading,
      };
    case "SET_USER_SEARCH":
      return {
        ...state,
        userSearch: action.id,
        userSearchObj: action.user,
      };
    case "GET_TASKS":
      return {
        ...state,
        tasks: action.tasks,
        tasksS: action.tasksS,
        params: action.params,
      };
    case "GET_TASKS_UP":
      return {
        ...state,
        tasks: action.tasks,
        //tasksS: action.tasksS,
        params: action.params,
      };
    case "UPDATE_TASKS":
      return { ...state };
    case "REORDER_TASKS":
      return { ...state, tasks: action.tasks };
    case "SELECT_TASK":
      return { ...state, selectedTask: action.task };
    default:
      return state;
  }
};
export default TodoReducer;

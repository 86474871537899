// ** Initial State
const initialState = {
  viewGraficos: false,
  idMonitor: 0,
  nameMonitor: "",
  url: "",
  idTabMonitor: 0,
  idItemTab: 0,
  idGraficoItem: 0,
  idPbi: 0,
  namePbi: "",

  browser: "",
  so: "",
  ip: "",
  listGraficoItem: [],
};

const TrackerState = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MONITOR":
      return {
        ...state,
        idMonitor: action.idMonitor,
        nameMonitor: action.nameMonitor,
        url: action.url,
      };
    case "SET_ID_TAB_MONITOR":
      return {
        ...state,
        idTabMonitor: action.idTabMonitor,
      };
    case "SET_ID_ITEM_TAB":
      return {
        ...state,
        idItemTab: action.idItemTab,
      };
    case "SET_ID_GRAFICO_ITEM":
      return {
        ...state,
        idGraficoItem: action.idGraficoItem,
      };
    case "SET_ID_PBI":
      return {
        ...state,
        idPbi: action.idPbi,
      };
    case "SET_NAME_PBI":
      return {
        ...state,
        namePbi: action.namePbi,
      };
    case "SET_MONITOR_TAB_ITEM":
      return {
        ...state,
        idMonitor: action.idMonitor,
        nameMonitor: action.nameMonitor,
        idTabMonitor: action.idTabMonitor,
        idItemTab: action.idItemTab,
        idPbi: 0,
      };
    case "SET_LIST_GRAFICO_ITEM":
      return {
        ...state,
        listGraficoItem: action.listGraficoItem,
      };

    case "SET_ITEM_TAB_GRAFICO":
      return {
        ...state,
        idItemTab: action.idItemTab,
        idGraficoItem: action.idGraficoItem,
      };

    case "SET_VIEW_GRAFICOS":
      return {
        ...state,
        viewGraficos: action.viewGraficos,
      };

    case "SET_BROWSER_SO":
      return {
        ...state,
        browser: action.browser,
        so: action.so,
      };

    case "SET_IP":
      return {
        ...state,
        ip: action.ip,
      };

    case "CLEAR":
      return {
        ...state,
        idMonitor: 0,
        nameMonitor: "",
        idTabMonitor: 0,
        idItemTab: 0,
        idGraficoItem: 0,
        idPbi: 0,
        namePbi: "",
      };

    default:
      return { ...state };
  }
};
export default TrackerState;

import { Fragment } from "react";
import { AlertTriangle, Check, Info, X } from "react-feather";
import Avatar from "@components/avatar";

const SuccessToast = ({ title, message, color = "success" }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={<Check size={12} />} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span>{message}</span>
    </div>
  </Fragment>
);

const ErrorToast = ({ title, message, color = "danger" }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={<X size={12} />} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span>{message}</span>
    </div>
  </Fragment>
);
const ErrorListToast = ({ title, message, color = "danger" }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={<X size={12} />} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <ul>
        {message?.map((val, index) => (
          <li key={"err-" + index}>{val}</li>
        ))}
      </ul>
    </div>
  </Fragment>
);
const AlertListToast = ({ title, message, color = "danger", icon }) => {
  const Icon = icon;
  return (
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color={color} icon={<Icon size={12} />} />
          <h6 className="toast-title font-weight-bold">{title}</h6>
        </div>
      </div>
      <div className="toastify-body">
        <ul>
          {message?.map((val, index) => (
            <li key={"err-" + index}>{val}</li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

const WarningToast = ({ title, message, color = "danger" }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={<AlertTriangle size={12} />} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        <span>{message}</span>
      </span>
    </div>
  </Fragment>
);

const InfoToast = ({ title, message, color = "info" }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={<Info size={12} />} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        <span>{message}</span>
      </span>
    </div>
  </Fragment>
);

export {
  SuccessToast,
  ErrorToast,
  ErrorListToast,
  WarningToast,
  InfoToast,
  AlertListToast,
};

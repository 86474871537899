const initialState = {
    graficoSelect: null,
  };
  
  const GraficoState = (state = initialState, action) => {
    switch (action.type) {
      case "SELECT_GRAFICO":
        return { ...state, graficoSelect: action.grafico };
  
      case "CLEAR_GRAFICO":
        return { ...state, graficoSelect: null };
  
      default:
        return { ...state };
    }
  };
  
  export default GraficoState;
const initialState = {
  loadingData: false,
  totalCount: 0,
  pageSize: 10,
  currentPage: 1,
  stringOrder: "",
  lastQuery: "",
  sorting: "",
  searchTerm: "",
  data: [],

  loadingFilters: false,
  allUsuarios: [],
  usuarioSelected: null,
  objFilter: null,
  clickBtnSearch: false,

  modalOpen: false,
  selectedReasignar: null,
  loadingDataReasignarAsesor: false,
  dataReasignarAsesorCampania: [],
  dataSelectedReasignar: [],

  loadingProcesarReasignar: false,

  paramsReasignarData: null,
};

const InfoCreditoVacacionState = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_FILTROS":
      return { ...state, loadingFilters: action.loading };

    case "LOADING_REASIGNAR_INFOCREDITO":
      return { ...state, loadingData: action.loading };

    case "LOADING_DATA_REASIGNAR_ASESOR":
      return { ...state, loadingDataReasignarAsesor: action.loading };
    case "LOADING_PROCESAR_REASIGNAR":
      return { ...state, loadingProcesarReasignar: action.loading };

    case "CLEAR_FILTER":
      return {
        ...state,
        campaniaSelected: null,
        agenciaSelected: null,
        regionSelected: null,
        usuarioSelected: null,
        objFilter: {},
      };

    case "SET_PARAMS_DATA_FILTER":
      return { ...state, paramsReasignarData: action.params };

    case "GET_USUARIOS":
      return { ...state, allUsuarios: action.usuarios };

    case "USUARIO_SELECTED_OUT":
      if (action.usuario) {
        return {
          ...state,
          usuarioSelected: action.usuario,
        };
      } else {
        return {
          ...state,
          usuarioSelected: null,
        };
      }
    case "GET_OBJ_FILTER_OUT":
      var data = {};
      if (state.usuarioSelected)
        data.username_in = state.usuarioSelected.usuario;

      return {
        ...state,
        objFilter: data,
      };

    case "GET_DATA_VACACIONES":
      return {
        ...state,
        data: action.data,
        totalCount: action.totalCount,
        loadingData: action.loading,

        //params: action.params,
      };

    case "SEARCH_TABLE_REASIGNAR":
      let search = null;
      if (action.value !== null && action.value !== "") {
        search = [
          { key: "documento", operator: "contains", value: action.value },
          { key: "nombres", operator: "contains", value: action.value },
          { key: "apellidos", operator: "contains", value: action.value },
        ];
      }
      return {
        ...state,
        searchTable: search,
      };

    case "OPEN_MODAL_REASIGNAR":
      return {
        ...state,
        modalOpen: true,
        //params: action.params,
      };

    case "CLOSE_MODAL_REASIGNAR":
      return {
        ...state,
        modalOpen: false,
        selectedReasignar: null,
        dataSelectedReasignar: [],
        //params: action.params,
      };

    case "SELECTED_REASIGNAR":
      return {
        ...state,
        selectedReasignar: action.row,
        //params: action.params,
      };
    //
    case "DATA_SELECTED_REASIGNAR":
      return {
        ...state,
        dataSelectedReasignar: action.rows,
        //params: action.params,
      };
    default:
      return { ...state };
  }
};

export default InfoCreditoVacacionState;

const initialState = {
  loadingDocumento: false,
  loadingPricing: false,
  resultDocumento: null,
  resultadoPricing: null,
  teasEstimadas: [],
};

const PricingState = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_SEARCH_DOCUMENTO":
      return { ...state, loadingDocumento: action.loading };
    case "LOADING_PRICING":
      return { ...state, loadingPricing: action.loading };

    case "CLEAR_PRICING":
      return {
        ...state,
        loadingDocumento: false,
        resultDocumento: null,
        resultadoPricing: null,
        teasEstimadas: [],
      };

    case "SET_RESULT_DOCUMENTO":
      return { ...state, resultDocumento: action.resultDocumento };
    case "SET_RESULT_PRICING":
      return { ...state, resultadoPricing: action.resultadoPricing };
    case "SET_TEAS_ESTIMADAS":
      return { ...state, teasEstimadas: action.teasEstimadas };
    default:
      return { ...state };
  }
};

export default PricingState;

// **  Initial State
const initialState = {
  loading: false,
  menuList: [],
  menuListComponent: [],
  menuTree: [],
  suggestion: [],
  whatsapps: [],
};

const opcMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_MENU_USER":
      return {
        ...state,
        loading: action.loading,
      };
    case "GET_MENU_USER":
      return {
        ...state,
        menuList: action.menu,
        menuListComponent: action.menucomponent,
        menuTree: action.menutree,
        suggestion: action.suggestion,
      };
    case "WHATSAPPS_USER":
      return {
        ...state,
        whatsapps: action.whatsapps,
      };
    default:
      return state;
  }
};

export default opcMenuReducer;

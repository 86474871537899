// ** Initial State
const initialState = {
  loading: false,
  allData: [],
  data: [],
  total: 1,
  selectedUser: null,
};

const usersActivity = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_DATA_LOG_USER":
      return { ...state, loading: action.loading };

    case "GET_ALL_DATA_LOG":
      return { ...state, allData: action.data };

    case "GET_USER":
      return { ...state, selectedUser: action.selectedUser };
    case "CLEAR_USER":
      return { ...state, selectedUser: null };
    default:
      return { ...state };
  }
};
export default usersActivity;

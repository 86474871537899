const initialState = {
  selectOpcMenu: null,
  activeTree: true,
  listOpcMenu: [],
  listOpcMenuFather: [],
  opcSelectChildren: [],
  father: null,
  loading: false,
  monitorsTree: [],
};

const AdminOpcMenuState = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.loading };
    case "SET_MONITORS_TREE":
      return { ...state, monitorsTree: action.monitorsTree };
    case "SELECT_OPC_MENU":
      let father1 = null;
      if (action.select !== null) {
        state.listOpcMenu.forEach((val) => {
          if (val.id === action.select.parent_id) {
            val.value = val.id;
            val.label = val.nombre;
            father1 = val;
          }
        });
      }

      return { ...state, selectOpcMenu: action.select, father: father1 };

    case "SET_LIST_OPC_MENU":
      return { ...state, listOpcMenu: action.listOpcMenu };

    case "SET_LIST_OPC_MENU_FATHER":
      return { ...state, listOpcMenuFather: action.listOpcMenuFather };

    case "SET_OPC_MENU_CHILDREN":
      if (state.selectOpcMenu === null)
        return { ...state, opcSelectChildren: [] };

      let father = [];
      state.listOpcMenu.forEach((val) => {
        //console.log(val, monitorSelected);
        if (val.parent_id === state.selectOpcMenu.id) father.push(val);
      });
      return { ...state, opcSelectChildren: father };

    case "CLEAR_OPC_MENU":
      return { ...state, selectOpcMenu: null, father: null };

    case "ENABLED_TREE":
      return { ...state, activeTree: action.activeTree };

    default:
      return { ...state };
  }
};

export default AdminOpcMenuState;

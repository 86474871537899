// ** Initial State

import { START_SESSION, UPDATE_SESSION, VIEW_ALERT } from "../action";

/* const duration = parseInt(localStorage.getItem("tokenExpire"), 10) || 0;
const remainingTime =
  parseInt(localStorage.getItem("remainingTime"), 10) || duration; */

const dateLS = localStorage.getItem("date");

const initialState = {
  timeLeft: 90,
  duration:0,
  remainingTime:0,
  viewAlert: true,
  date: dateLS,
};

const SessionTimerState = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TIME_LEFT":
      return {
        ...state,
        timeLeft: action.timeLeft,
      };

    case START_SESSION:
      localStorage.setItem("tokenExpire", action.payload);
      localStorage.setItem("remainingTime", action.payload);
      return {
        ...state,
        duration: action.payload,
        remainingTime: action.payload,
      };
    case UPDATE_SESSION:
      localStorage.setItem("remainingTime", action.payload);
      return {
        ...state,
        remainingTime: action.payload,
      };
    case VIEW_ALERT:
      return {
        ...state,
        viewAlert: action.payload,
      };

    default:
      return { ...state };
  }
};

export default SessionTimerState;
